import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('@/pages/login/index')
  },
  {
    path: '/register',
    component: () => import('@/pages/register/index')
  },
  {
    path: '/registerM',
    component: () => import('@/pages/register/index_m')
  },
  {
    path: '/auth',
    component: () => import('@/pages/auth/index')
  },
  {
    path: '/success',
    component: () => import('@/pages/auth/success')
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Home',
      component: () => import('@/views/Home'),
      meta: { title: '首页',auth:false,fixed:true }
    },{
      path: 'news',
      name: 'News',
      component: () => import('@/views/News'),
      meta: { title: '最新资讯',auth:false,fixed:true } // fixed 是否固定定位
    },{
      path: '/newsDetail/:newsId',
      name:'NewsDetail',
      component: () => import('@/views/News/detail'),
      meta:{ title: '资讯详情',auth:false,fixed:true }
    },{
      path: 'newsSearch',
      name: 'NewsSearch',
      component: () => import('@/views/News/search'),
      meta: { title: '资讯查询',auth:false,fixed:true }
    }]
  },
  {
    path: '/selection',
    component: Layout,
    children: [{
      path: 'index',
      name: 'Selection',
      component: () => import('@/views/Selection'),
      meta: { title: '选品中心',auth:false,fixed:true }
    },{
      path: '/distribution',
      name: 'Distribution',
      component: () => import('@/views/Selection/distribution'),
      meta: { title: '全部商品',auth:false,fixed:true }
    },{
      path: '/product/:productId',
      name: 'Product',
      component: () => import('@/views/Selection/productDetail'),
      meta: { title: '商品详情',auth:false,fixed:true }
    },{
      path: '/productSearch',
      name: 'ProductSearch',
      component: () => import('@/views/Selection/search'),
      meta: { title: '商品查询',auth:false,fixed:true }
    },{
      path: '/productNews',
      name: 'ProductNews',
      component: () => import('@/views/Selection/news'),
      meta: { title: '选品头条',auth:false,fixed:true }
    },{
      path: '/beltList',
      name: 'BeltList',
      component: () => import('@/views/Selection/beltList'),
      meta: { title: '南安产业带',auth:false,fixed:true }
    },{
      path: '/company/:companyId',
      name: 'Company',
      component: () => import('@/views/Selection/companyDetail'),
      meta: { title: '南安产业带',auth:false,fixed:true }
    }],
  },
  {
    path: '/comprehensive',
    component: Layout,
    children: [{
      path: 'index',
      name: 'Comprehensive',
      component: () => import('@/views/Comprehensive'),
      meta: { title: '综合服务',auth:false,fixed:true }
    },{
      path: '/providerList/:ids?',
      name: 'Provider',
      component: () => import('@/views/Comprehensive/provider'),
      meta: { title: '服务商资源',auth:false,fixed:true }
    },{
      path: '/providerDetail/:providerId',
      name: 'ProviderDetail',
      component: () => import('@/views/Comprehensive/providerDetail'),
      meta: { title: '服务商详情',auth:false,fixed:true }
    },{
      path: '/logistics/:ids?',
      name: 'Logistics',
      component: () => import('@/views/Comprehensive/logistics'),
      meta: { title: '跨境物流',auth:false,fixed:true }
    }],
  }, 
  {
    path: '/openShop',
    component: Layout,
    children: [{
      path: 'index',
      name: 'OpenShop',
      component: () => import('@/views/Shop'),
      meta: { title: '一键开店',auth:false,fixed:true }
    },{
      path: '/shopDetail/:shopId',
      name: 'ShopDetail',
      component: () => import('@/views/Shop/shopDetail'),
      meta: { title: '平台详情',auth:false,fixed:true }
    },{
      path: '/shopApply',
      name: 'ShopApply',
      component: () => import('@/views/Shop/apply'),
      meta: { title: '一键申请开店',auth:true,fixed:true }
    },{
      path: '/shopSearch',
      name: 'ShopSearch',
      component: () => import('@/views/Shop/search'),
      meta: { title: '一键开店',auth:false,fixed:true }
    },{
      path: '/shopNews',
      name: 'ShopNews',
      component: () => import('@/views/Shop/news'),
      meta: { title: '开店快讯',auth:false,fixed:true }
    }]
  },
  {
    path: '/eCommerce',
    component: Layout,
    children: [{
      path: 'index',
      name: 'ECommerce',
      component: () => import('@/views/ECommerce'),
      meta: { title: '电商学院',auth:false,fixed:true }
    },{
      path: '/courseDetail/:courseId',
      name: 'CourseDetail',
      component: () => import('@/views/ECommerce/courseDetail'),
      meta: { title: '课程详情',auth:false,fixed:true }
    },{
      path: '/ecommerceSearch',
      name: 'EcommerceSearch',
      component: () => import('@/views/ECommerce/search'),
      meta: { title: '电商学院',auth:false,fixed:true }
    },{
      path: '/hatchApply',
      name: 'HatchApply',
      component: () => import('@/views/ECommerce/apply'),
      meta: { title: '线下孵化申请',auth:true,fixed:true }
    }]
  },
  {
    path: '/user',
    component: Layout,
    children: [{
      path: 'index',
      name: 'User',
      component: () => import('@/views/User'),
      meta: { title: '个人中心',auth:true,fixed:true }
    }]
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
    if ( resolve || reject ) return originalPush.call(this, location, resolve, reject)
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior: () => ({ y: 0 })
})

// router.afterEach((to, from) => {
//   if (to.meta.title) {
//     titleController.setRouteTitle(to.meta.title)
//   }
// })
export default router
